<template>
  <v-container fluid>
    <FilterDrawer
      current-component="factor-filter"
      @filter="filterFactor"
      @clear="clearFilter"
    />

    <Table
      :data="dataTable"
      :total-pages="dataTable.totalPages"
      @setCurrentPage="setCurrentPage"
      @search="search"
    >
      <template v-slot:tbody="{ item }">
        <td>{{ item.cost }}</td>
        <td class="text-center">
          <StatusChip :current-status="item.status" />
        </td>
        <td class="text-right">
          {{ item.updated_at }}
        </td>
      </template>
    </Table>
  </v-container>
</template>

<script>
import { contextMenu, massAction } from '@/main.js'
import Table from '@/components/data-table/Table'
import FilterDrawer from '@/components/FilterDrawer'
import StatusChip from '@/components/data-table/StatusChip'
import checkProfileRoutePermission from '@/mixins/checkProfileRoutePermission'

export default {
  components: {
    Table,
    FilterDrawer,
    StatusChip,
  },
  mixins: [checkProfileRoutePermission],
  data: () => ({
    currentSearch: '',
    dataTable: {
      title: 'Fatores',
      new: 'factor-new',
      edit: 'factor-edit',
      context: 'factor-context',
      massAction: 'factor-mass-action',
      totalPages: 1,
      headerButtons: {
        filter: true,
        new: true,
      },
      items: [],
      isLoading: true,
      headers: [
        { text: 'Custo', value: 'cost' },
        { text: 'Status', value: 'status', align: 'center' },
        { text: 'Atualizado em', value: 'updated_at', align: 'end' },
      ],
    },
  }),
  created: function () {
    this.getFactor()

    contextMenu.$once('action', (type) => {
      if (type === 'factors') {
        this.getFactor()
      }
    })
    massAction.$once('action', () => {
      this.getFactor()
    })
  },
  methods: {
    setCurrentPage(page) {
      this.getFactor(page)
    },
    search(search) {
      this.currentSearch = search
      this.getFactor()
    },
    getFactor(page = 1) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .get('product_factors', {
          params: {
            page,
            search: this.currentSearch,
          }
        })
        .then((res) => {
          this.dataTable.totalPages = res.data.total_pages;
          this.dataTable.items = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    filterFactor(obj) {
      this.dataTable.items = []
      this.dataTable.isLoading = true

      this.$api
        .post('product_factors/filter', obj)
        .then((res) => {
          this.dataTable.items = res.data.data
        })
        .catch(() => {
          this.dataTable.items = []
        })
        .finally(() => {
          this.dataTable.isLoading = false
        })
    },
    clearFilter() {
      this.getFactor()
    },
  },
}
</script>
